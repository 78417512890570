import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "date": "2021-08-31T00:00:00.000Z",
  "title": "JavaScript Concepts : Dot and Bracket Notation",
  "author": "Nichele Dunn"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`JavaScript Concepts : Dot and Bracket Notation`}</h2>
    <p>{`📆 31 Aug 2021`}</p>
    <p>{`Dot and bracket notation... What are they for? Why would I use one or the other? Is it even important to know the differences? How do they work? Did you know that dot and bracket notation can also be referred to as property accessors? `}</p>
    <section className="tldr">tl;dr: Dot and bracket notation are used to access object values, using their keys. Usually, you will want to use dot notation, but sometimes, you will <i>have</i> to use bracket notation. Use the syntax <code>object.property</code> or <code>object["property"]</code> to get the values you're looking for.</section>
    <p>{`Beyond that tidbit, here are some other interesting things about this useful JavaScript tool.`}</p>
    <h3>{`Syntax`}</h3>
    <p>{`Let's start with the syntax. We have two options when using property accessors.`}</p>
    <p><strong parentName="p">{`Dot `}<inlineCode parentName="strong">{`.`}</inlineCode>{` notation : `}<inlineCode parentName="strong">{`object.property;`}</inlineCode></strong></p>
    <p><strong parentName="p">{`Bracket `}<inlineCode parentName="strong">{`[]`}</inlineCode>{` notation : `}<inlineCode parentName="strong">{`object["property"];`}</inlineCode></strong></p>
    <p>{`The `}<inlineCode parentName="p">{`property`}</inlineCode>{` is the key you are attempting to access within an object. Property accessors give us the ability to obtain these values. Neat!`}</p>
    <h3>{`How to choose?`}</h3>
    <p>{`🕵️‍♀️ Start by asking yourself a question : "Do I know the key names I want to access?"`}</p>
    <p><strong parentName="p">{`If the answer is `}<inlineCode parentName="strong">{`yes`}</inlineCode>{`, use dot notation!`}</strong></p>
    <p>{`Because dot notation is so easy to use, go with dot notation! Unless you can't. And sometimes you just `}<em parentName="p">{`can't`}</em>{` use the dot, because you don't know what you're looking for, or because the key has a weird name that doesn't follow the rules.`}</p>
    <p>{`Remember, when using dot notation, one must follow the standard naming rules for JavaScript identifiers. (If you don't remember those rules, `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Property_accessors"
      }}>{`here's a refresher`}</a>{`.)`}</p>
    <p><strong parentName="p">{`If the answer is `}<inlineCode parentName="strong">{`no`}</inlineCode>{`, use bracket notation!`}</strong></p>
    <p>{`If you don't know the key names, use brackets! You can use `}<em parentName="p">{`variables`}</em>{` to identify unknown or weird key names using the syntax `}<inlineCode parentName="p">{`object[property_name]`}</inlineCode>{`, where `}<inlineCode parentName="p">{`property_name`}</inlineCode>{` can be represented by a string or Symbol. Because JavaScript relies upon type coercion, these values will be coerced into strings.`}</p>
    <h3>{`Dot Notation`}</h3>
    <p>{`🏁 To get some practice, let's create an object. Copy this code into your console to follow along. There is also a `}<a parentName="p" {...{
        "href": "https://replit.com/@nichelicorn/jediOne#index.js"
      }}>{`replit you can fork`}</a>{` to get some practice and follow along.`}</p>
    <pre><code parentName="pre" {...{}}>{`const jediOne = {
  name: "Ahsoka Tano",
  homeworld: "Shili"
}
`}</code></pre>
    <p>{`When thinking about the properties a Jedi might have, it's safe to assume they will each have a name and a homeworld, so we can use those to check out some dot notation.`}</p>
    <p>{`🏁 Let's get the name property first. To access the value of the name property, type the following in the console: the object name, then a dot, then the word name. Or, just copy this code:`}</p>
    <pre><code parentName="pre" {...{}}>{`jediOne.name;
`}</code></pre>
    <p>{`This should log the Jedi's name, `}<a parentName="p" {...{
        "href": "https://starwars.fandom.com/wiki/Ahsoka_Tano"
      }}>{`Ahsoka Tano`}</a>{`!`}</p>
    <p>{`🌶 Try it out with the homeworld. Having trouble? Follow the example for getting the value of the name and try again. You may also be getting some hints from your console, and that's great! You can use those prompts to help you learn too.`}</p>
    <p>{`Let's move on to bracket notation.`}</p>
    <h3>{`Bracket notation`}</h3>
    <p>{`🏁  Bracket notation can be used much like dot notation when you know the key. This code should give you the same string you accessed using the dot notation:`}</p>
    <pre><code parentName="pre" {...{}}>{`jediOne["name"];
`}</code></pre>
    <p>{`Beyond matching the usefulness of dots, bracket notation gives you even more power for accessing and manipulating values dynamically. When using bracket notation, the key name does not need to be known! If a variable is created, JavaScript has the power to search an object for a matching property, and return that value if a match is found. This gives flexibility in how we interact with objects.`}</p>
    <p>{`🏁 Let's see if we can discover the Jedi's homeworld using this power (and pretend you haven't already examined this property). Type or copy the following code in the console:`}</p>
    <pre><code parentName="pre" {...{}}>{`const mysteryPlanet = "homeworld";

jediOne[mysteryPlanet];
`}</code></pre>
    <p>{`This should give you the name of Ahsoka's homeworld, Shili!`}</p>
    <p>{`📌 Take note of the syntax when calling on a variable to do the work of the accessor -- you don't need quotations! JavaScript knows that in this example, mysteryPlanet is a variable, and it's not looking for a matching key in the jediOne object. What happens if you `}<em parentName="p">{`do`}</em>{` include the quotations?`}</p>
    <h3>{`Adding properties with brackets`}</h3>
    <p>{`🏁 Next, let's add a new property to our `}<inlineCode parentName="p">{`jediOne`}</inlineCode>{` object using bracket notation. To do this, you'll want to follow this example:`}</p>
    <pre><code parentName="pre" {...{}}>{`jediOne["jediMaster"] = "Anakin Skywalker";
`}</code></pre>
    <p>{`Now, `}<inlineCode parentName="p">{`jediOne`}</inlineCode>{` should have a third property!`}</p>
    <p>{`🏁 Try adding additional properties - you can follow the same process using bracket notation, or try it with dot notation instead. 🌶 If you want to get spicy, write a function to do the work for you!`}</p>
    <h3>{`Chaining and nesting`}</h3>
    <p>{`Dot and bracket notation can also be chained or nested.`}</p>
    <p>{`🏁 Let's add another property to `}<inlineCode parentName="p">{`jediOne`}</inlineCode>{` to test out these tools.`}</p>
    <pre><code parentName="pre" {...{}}>{`jediOne.lightsabers = [
  {
    hilt: "silver",
    blade: "green",
    pair: false
  },
  {
    hilt: "silver",
    blade: "yellow-green",
    pair: false
  },
  {
    hilt: "silver",
    blade: "white",
    pair: true
  }
];
`}</code></pre>
    <p>{`Ahsoka should now have an array of lightsabers! And we can get their values by using some fancy notation.`}</p>
    <p>{`🏁 Try accessing the pair of white lightsabers and logging this string in the console: "Ahsoka Tano has a pair of lightsabers with white blades."`}</p>
    <p>{`What did you come up with?`}</p>
    <p>{`Was it this? `}</p>
    <pre><code parentName="pre" {...{}}>{`\`\${jediOne.name} has a pair of lightsabers with \${jediOne.lightsabers[2].blade} blades.\`
`}</code></pre>
    <p>{`Or maybe something else? Did you get the result you wanted! If you did, yay!!! 🎉 Keep experimenting with this object and see what else you can do. If you aren't getting the results you want, try something else. And if you're getting red in the console, follow the error messages. 🕵️‍♀️`}</p>
    <section className="post-resources">
  <p>Thank you for reading this post! 💚 Check out these ⬇️ resources for more info.</p>
  <div>
    <ul className="resource-list">
      <li>jediOne <a href="https://replit.com/@nichelicorn/jediOne#index.js">replit playground</a></li>
      <li>MDN : <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Property_accessors">Property accessors </a></li>
      <li>MDN : <a href="https://developer.mozilla.org/en-US/docs/Glossary/Symbol">JavaScript Symbols</a></li>
      <li>MDN : <a href="https://developer.mozilla.org/en-US/docs/Glossary/Identifier">JavaScript Identifier</a></li>
      <li>Samantha Ming : <a href="https://medium.com/dailyjs/dot-notation-vs-bracket-notation-eedea5fa8572">Dot notation vs Bracket notation</a></li>
      <li>Wookiepedia : <a href="https://starwars.fandom.com/wiki/Ahsoka_Tano">Ahsoka Tano</a></li>
    </ul>
  </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      